<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <div class="d-flex flex-grow-1">
        <div>
          <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title"
            :class="`text-${variant}`"
            v-text="title"
          />
          <small v-if="text" class="d-inline-block text-body" v-text="text" />
        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto"
          @click="$emit('close-toast')"
        >
          <b-icon v-if="!hideClose" class="h4 mb-0" :variant="variant" icon="x"></b-icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { BIcon } from 'bootstrap-vue-3'

export default {
  components: {
    BIcon,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
  font-size: 16px;
}

.toastification-title {
  color: inherit;
}
.Vue-Toastification__toast--default {
  background: #fff;
  border: 1px solid var(--bs-success);
}
</style>
