<template>
  <div>
    <title-bar :title="$t('navigations.invoices')"/>
    <div v-if="this.rows" class="custom-table">
      <table-actions
        :actions="['perPage', 'search']"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="{}"
        @is-finished="isLoading = false"
      >
        <template v-slot:id="data">
          {{ data.value.id }}
        </template>
        <template v-slot:name="data">
            <span v-if="data.value.client">
              {{ data.value.client.givenName + ' ' + data.value.client.familyName }}
            </span>
        </template>
        <template v-slot:totalPrice="data">
          {{ data.value.totalPrice }}
        </template>
        <template v-slot:createdAt="data">
          {{ $helper.formatDate(data.value.createdAt) }}
        </template>
        <template v-slot:status="data">
          <b-badge variant="confirmed">{{ data.value.status }}</b-badge>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <a href="javascript:void(0)" @click="generateVoucher(data.value.id)">
              <BootstrapIcon icon="eye" size="1x"/>
            </a>
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import {BBadge} from 'bootstrap-vue-3'
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  name: "AdminCustomersListing",
  components: {
    TableLite,
    TablePagination,
    TableActions,
    TitleBar,
    BBadge,
  },
  mixins: [ResourceUtils],
  data() {
    return {
      search: "",
      isLoading: false,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      sortable: {
        order: "createdAt",
        sort: "DESC",
      },
      columns: [
        {
          label: '#',
          field: "id",
          sortable: false,
        },
        {
          label: this.$t('forms.name'),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t('forms.total'),
          field: "totalPrice",
          sortable: false,
        },
        {
          label: this.$t('forms.createdAt'),
          field: "createdAt",
          sortable: false,
        },
        {
          label: this.$t('forms.status'),
          field: "status",
          sortable: false,
        },
        {
          label: this.$t('forms.actions'),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.loadVouchers();
  },
  methods: {
    loadVouchers() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
      };
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      params["order[" + this.sortable.order + "]"] = this.sortable.sort;
      params['status'] = ['CONFIRMED']
      this.$Orders.getCollection({params}, 'invoice_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];
      });
    },
    generateVoucher(id) {
      const body = {
        name: 'voucher_' + id,
        resource: '/orders/' + id,
        templateName: "VOUCHER"
      }
      this.$Pdfs.getResourceByUrl({url: `/pdfs/voucher_${id}`}).then(response => {
        if (response.status === 200) {
          const link = document.createElement('a')
          link.href = response.data.url;
          link.target = '_blank'
          link.click()
        }
      }).catch(() => {
        this.$Pdfs.createResource({body}).then(response => {
          if (response.status === 201) {
            const link = document.createElement('a')
            link.href = response.data.url;
            link.target = '_blank'
            link.click()
          }
        })
      })
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadVouchers();
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadVouchers();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadVouchers();
    },
    onPageChange() {
      this.loadVouchers();
    },
  },
};
</script>

<style lang="scss">
</style>
